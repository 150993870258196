// common functions to be shared in widgets
export function _refinedDisjunctiveFacets (state) {
  return state.disjunctiveFacets.filter(function (t) { return state.isDisjunctiveFacetRefined(t); });
};
export function _isRefinedOrQuerried (state) {
  return !(
    state.query.length == 0 && _refinedDisjunctiveFacets(state).length == 0
  );
};
export function _showHideTargetElementOnQuerryOrRefined (state, targetElementId) {
  if (document.querySelector(targetElementId)===null) { return }
  if (_isRefinedOrQuerried(state)) {
    document.querySelector(targetElementId).classList.remove("hide");
  } else {
    document.querySelector(targetElementId).classList.add("hide");
  }
};
export function noResultsTemplate (query) {
  return `
  <div class="row fc-portal-dark-blue-text mb0">
    <div class="col s12 l8 offset-l2 center">
      <img
        alt="No Results"
        src="https://fidocure.imgix.net/assets/dvm/no_results_icon.svg"
      />
      <h2>Sorry, there were no matching results for "${query}".</h2>
    </div>
  </div>
  <div class="row fc-portal-dark-blue-text">
    <div class="col s8 m6 l4 offset-s2 offset-m3 offset-l4 center">
      <hr class="styled-hr fc-portal-sand-dark-2" />
    </div>
  </div>
  <div class="row fc-portal-dark-blue-text">
    <div class="col s12 l8 offset-l2">
      <div class="mb0_75 mt1_25">Some Tips for Searching:</div>
      <div class="flex">
        <i class="material-icons fc-portal-sand-dark-2-text font-18 mr0_5 mt0_25"
          >check</i
        >
        <div>
          Double check your spelling to make sure you entered your search term
          correctly.
        </div>
      </div>
      <div class="flex">
        <i class="material-icons fc-portal-sand-dark-2-text font-18 mr0_5 mt0_25"
          >check</i
        >
        <div>You can search by veterinarian, patient, or pet parent name.</div>
      </div>
      <div class="flex">
        <i class="material-icons fc-portal-sand-dark-2-text font-18 mr0_5 mt0_25"
          >check</i
        >
        <div>
          You can also search by
          <span class="semi-bold">hospital</span>
          or
          <span class="semi-bold">clinic name. </span>
        </div>
      </div>
      <div class="mt1_5">Still can't find what you're looking for?</div>
      <div>
        Contact FidoCure customer support at
        <a href="tel:(415) 273-8863">(415) 273-8863</a> or email
        <a href="mailto:support@fidocure.com">support@fidocure.com</a>
        .
      </div>
    </div>
  </div>
`;
};
