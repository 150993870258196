// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus"
import {
  show_element,
  show_DOM_element,
  hide_element,
  hide_DOM_element,
  check_flash_messages,
  initialize_textarea,
  toggle_other,
  show_tab_medication_required,
  hide_tab_medication_required
} from "./outcomes-lib";

export default class extends Controller {
  static targets = [
    'performanceRow',
    'physicalExamDate',
    'heartRate',
    'breathRate',
    'weight',
    'bodyTemp',
    'medicationRequiredMessage',
    'medicationRequiredMessageByYesSpecification'
  ];

  initialize() {}

  connect() {
    console.log('Hello, Stimulus from outcomes > PE > controller!');
    check_flash_messages();
    initialize_textarea();
  };

  select_performance(_event) {
    _event.stopPropagation();
    const currentElement = _event.target.dataset.checkValue;
    const checkboxes = this.performanceRowTarget.querySelectorAll('input');

    checkboxes.forEach(element => {
      element.checked = element.id === currentElement;
    });
  };

  // TODO: Remove this code as the functionality is not being used now.
  field_change() {
    let showAllFieldRequiredMessage;

    const physicalExamDate = this.physicalExamDateTarget;
    const heartRate = this.heartRateTarget;
    const breathRate = this.breathRateTarget;
    const weight = this.weightTarget;
    const bodyTemp = this.bodyTempTarget;

    showAllFieldRequiredMessage = (physicalExamDate && physicalExamDate.value !== "") ||
                                  (heartRate && heartRate.value !== "") ||
                                  (breathRate && breathRate.value !== "") ||
                                  (weight && weight.value !== "") ||
                                  (bodyTemp && bodyTemp.value !== "");

    return showAllFieldRequiredMessage ? show_element('allFieldsRequiredMessage') : hide_element('allFieldsRequiredMessage');
  };

  toggle_LA_dosage_modification(_event) {
    const element = _event.target.closest('label');
    const inputValue = element.querySelector('input').value;

    if(inputValue === 'Yes') {
      show_element('dosageSpecifySection');
      this.show_medication_required_by_Yes_specification();
    } else {
      hide_element('dosageSpecifySection');
      this.hide_medication_required_by_Yes_specification();
    }
  };

  hide_medication_required_by_Yes_specification() {
    hide_DOM_element(this.medicationRequiredMessageByYesSpecificationTarget);

    // check if the mark is not required by another question relative to it in the form.
    const medicationRequiredMessageElement = this.medicationRequiredMessageTarget;
    const medicationRequiredMessage = window.getComputedStyle(medicationRequiredMessageElement);

    if(medicationRequiredMessage.display === 'none') {
      hide_tab_medication_required();
    }
  };

  hide_adverse_event_required = () => {
    hide_element('adverseEventRequiredMessage');
    hide_element('adverseEventRequiredMark');
  };

  hide_medication_required = () => {
    hide_DOM_element(this.medicationRequiredMessageTarget);

    // check if the mark is not required by another question relative to it on the form.
    const medicationRequiredMessageByYesSpecificationElement = this.medicationRequiredMessageByYesSpecificationTarget;
    const medicationRequiredMessageByYesSpecification = window.getComputedStyle(medicationRequiredMessageByYesSpecificationElement);

    if(medicationRequiredMessageByYesSpecification.display === 'none') {
      hide_tab_medication_required();
    }
  };

  hide_element_by_id = _event => {
    const identifierElementToHide = _event.target.closest('label').dataset.identifierElementToHide;
    hide_element(identifierElementToHide);
  };

  show_medication_required_by_Yes_specification() {
    show_DOM_element(this.medicationRequiredMessageByYesSpecificationTarget);
    show_tab_medication_required();
  };

  show_medication_required = () => {
    show_DOM_element(this.medicationRequiredMessageTarget);
    show_tab_medication_required();
  };

  show_adverse_event_required = () => {
    show_element('adverseEventRequiredMessage');
    show_element('adverseEventRequiredMark');
  };

  show_element_by_id = _event => {
    const identifierElementToShow = _event.target.dataset.identifierElementToShow;
    show_element(identifierElementToShow);
  }

  show_body_system_item(_event) {
    const id = _event.target.closest('label').dataset.id;
    const element = document.getElementById(id);
    if(!element) return;

    show_DOM_element(element);

    const mainContainer = element.closest('.body-system-item');
    const textarea = mainContainer.getElementsByTagName('textarea')[0];

    if(textarea) {
      textarea.focus();
    }
  };

  toggle_other_describe = _event => {
    toggle_other(_event);
  };
}
