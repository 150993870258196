import { Application, Controller } from "stimulus";
import { KmSearch } from "../../search_km/km.js"
export default class extends Controller {
  //static targets = ["result"];

  initialize() {
    //console.log('this is algolia initialize');
  }
  connect() {
    //console.log('this is algolia controller');
    const algoliaApp = this.element.dataset.algoliaApp;
    const algoliaIndex = this.element.dataset.algoliaIndex;
    const algoliaKey = this.element.dataset.algoliaKey;
    KmSearch(algoliaIndex, algoliaApp, algoliaKey);
  };
}
