// common functions to be shared in widgets
export function _refinedDisjunctiveFacets (state) {
  return state.disjunctiveFacets.filter(function(t) { return state.isDisjunctiveFacetRefined(t); });
}
export function _refinedNumericRefinements (state) {
  // NOTE: had to implement this because isNumericRefined was not working.  This function works.
  // console.log('111', Object.keys(state.numericRefinements).map(function(t) {return state.isNumericRefined(t) }))
  return Object.keys(state.numericRefinements).map(function(t) {
    if ((state.numericRefinements[t]['>='] && state.numericRefinements[t]['>='].slice(-1)[0] != 0) || !((state.numericRefinements[t]["<="] === undefined ) || (state.numericRefinements[t]["<="].length == 0))){
      return t
    }
  }).filter(function (el) {
    return el != null;
  });
}
export function _isRefinedOrQuerried (state) {
  return !(state.query.length == 0 && _refinedDisjunctiveFacets(state).length == 0 && _refinedNumericRefinements(state).length == 0);
}
export function _showHideTargetElementOnQuerryOrRefined (state, targetElementId) {
  if (document.querySelector(targetElementId)===null) { return }
  if (_isRefinedOrQuerried(state)) {
     document.querySelector(targetElementId).classList.remove("hide");
  }
  else {
     document.querySelector(targetElementId).classList.add("hide");
  }
}
