// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus"
import {
  show_element,
  show_DOM_element,
  hide_element,
  hide_DOM_element,
  check_flash_messages,
  header_text_input_focus,
  toggle_other,
  set_focus_input_in_card_header,
  set_focus_section,
  header_element_update_title,
  undo_action,
  collapse_card,
  submit_save_later
} from "./outcomes-lib";

export default class extends Controller {
  static targets = [];

  initialize() {}

  connect() {
    console.log('Hello, Stimulus from outcomes > common > controller!');
    check_flash_messages();
  }

  previous_done(_event) {
    const element = _event.target;
    const li = element.closest('li');
    li.classList.remove('active');

    const body = li.getElementsByClassName('collapsible-body');
    body[0].style.display = 'none';

    set_focus_section(li);
    return submit_save_later();
  };
}
