require("./materialize.min.js")
// Mobile sidebar toggle
export function burgerToggle() {
  const mobileBurger = document.getElementById("mobile-burger");
  const sidenav = document.getElementsByClassName("sidenav")[0];
  const sideNavInstance = M.Sidenav.getInstance(sidenav);

  // Toggle cross animation
  mobileBurger.classList.toggle("cross");
  // Open/close functionality
  sideNavInstance.options.onOpenEnd = () => mobileBurger.classList.remove("sidenav-trigger");
  sideNavInstance.options.onCloseStart = () => mobileBurger.classList.remove("cross");
  sideNavInstance.options.onCloseEnd = () => mobileBurger.classList.add("sidenav-trigger");
  sideNavInstance.isOpen && sideNavInstance.close();
};

