import { Application, Controller } from "stimulus";

export default class extends Controller {
  static targets = ["result"];

  valueChanged() {
    this.targets.find("result").
    innerHTML = "Some input has changed its value";
  }
}
