import { _refinedDisjunctiveFacets, _isRefinedOrQuerried, _showHideTargetElementOnQuerryOrRefined } from './common.js'
// Responsible for saving the current search onto the form_with so it can be sent to Rails
export function saveWidget() {
  return {

    init: function (params) {
      // TODO: maybe hide the save form when new tab is opened to avoid confusion
    },

    render: function (params) {
      let refinements = {...params.state.disjunctiveFacetsRefinements} // Shallow clone -- don't modify state
      // Remove those keys for which the array is empty
      Object.keys(refinements).forEach((key) => (refinements[key].length == 0) && delete refinements[key])

      let query = params.state.query
      let search_state = {
        "refinementList": refinements,
        "query": query
      }

      const hidden_element_for_storing_current_search = document.querySelector('#current_search_params');
      hidden_element_for_storing_current_search.value = JSON.stringify(search_state);

      _showHideTargetElementOnQuerryOrRefined(params.state, '#save_search_form');
    }
  }
}
