// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus"
import { submit_save_later } from "./outcomes-lib";

export default class extends Controller {
  static targets = [
    'dropdownTrigger'
  ];

  initialize() {}

  connect() {
    console.log('Hello, Stimulus from outcomes menu controller!');
    const element = document.getElementById('dropdownTrigger');
    M.Dropdown.init(element, { hover: false });

    const actions = document.querySelectorAll('.fixed-action-btn');
    M.FloatingActionButton.init(actions, { direction: "left" });

    let tooltipInstances;
    const tooltips = document.querySelectorAll('.tooltipped');
    tooltipInstances = M.Tooltip.init(tooltips);

    // You should remove this event listener when it is no longer needed.
    window.addEventListener('scroll', () => {
      for (let i = 0; i < tooltipInstances.length; ++i) {
        tooltipInstances[i]._positionTooltip();
      }
    });
  }

  nav_select(_event) {
    const redirectTo = _event.target.nextElementSibling.value;
    submit_save_later(redirectTo);
  };

  select_step_nav(_event) {
    const stepElement = _event.target;
    const stepSelected = stepElement.options[stepElement.selectedIndex].value;
    submit_save_later(stepSelected);
  };

  clear_form(_event) {
    console.log('> clear form');
  }
}
