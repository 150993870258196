// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [
    "consultationLink"
  ];

  initialize() {
    this.set_consultation_link();
  }

  connect() {}

  set_consultation_link = async () => {
    let checked = document.querySelectorAll('input[name="consultation_cases"]:checked');
    let checked_arr = Array.from(checked);
    let checked_values = checked_arr.map((checkbox) => checkbox.value);
    const response = await fetch("/utils/consultation_link", {
      method: 'post',
      body: JSON.stringify({'case_ids': checked_values}),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
      }
    })
    this.consultationLinkTarget.href = await response.json();
  }

  schedule_consultation = async (_event) => {
    document.querySelectorAll('input[name="consultation_cases"]:checked').forEach(function (elem) {
      elem.checked = false;
    });
    this.set_consultation_link();
  }
}
