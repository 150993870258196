export const STEPS = ['physical_exams', 'lesion_assessment', 'adverse_event', 'medication', 'summary'];

const getID = () => {
  return new Date().getTime();
};

const build_new_item_content = (identifier, templateIdentifier, classNameForContainer, fields) => {
  let template;
  let templateExpression;

  switch (templateIdentifier) {
    case 'new_record_target_therapies':
      templateExpression = /new_record_target_therapies/g;
      break;

    case 'new_record_concomitant_medications':
      templateExpression = /new_record_concomitant_medications/g;
      break;

    case 'new_record_supplements':
      templateExpression = /new_record_supplements/g;
    break;

    case 'new_record_TL':
      templateExpression = /new_record_TL/g;
    break;

    case 'new_record_NTL':
      templateExpression = /new_record_NTL/g;
    break;

    case 'new_record_new_lesion':
      templateExpression = /new_record_new_lesion/g;
    break;

    case 'new_record':
      templateExpression = /new_record/g;
    break;

    default:
      templateExpression = null;
  }

  if(!templateExpression) return;

  template = fields.replace(templateExpression, identifier);

  return `<li id='${identifier}' class="entity-new-item ${classNameForContainer} ml1">
          ${template}
          <input type="hidden" class='template-item' value="true">
          </li>`;
};

const save_for_later_clear_flash_messages = () => {
  hide_element('flashSaved');
  hide_element('flashError');
  show_element('saveLater');
};

const show_expanded_item = item => {
  item.classList.add('active');
  item.classList.add('show');
  const body = item.getElementsByClassName('collapsible-body');
  body[0].style.display = 'block';

  set_focus_input_in_card_header(item);
};

const remove_DOM_element = element => {
  element.remove();
};

export const set_focus_input_in_card_header = item => {
  const header = item.getElementsByClassName('collapsible-header');
};

export const show_element = id => {
  const element = document.getElementById(id);
  if(!element) return;

  element.classList.remove('hide');
  element.classList.add('show');
};

export const show_DOM_element = element => {
  element.classList.remove('hide');
  element.classList.add('show');
};

export const hide_element = id => {
  const element = document.getElementById(id);
  if(!element) return;

  element.classList.remove('show');
  element.classList.add('hide');
};

export const hide_DOM_element = element => {
  element.classList.add('hide');
  element.classList.remove('show');
};

export const submit_save_later = redirectURL => {
  const forms = document.getElementsByTagName('form');
  if(!forms || forms.length < 1) return;

  const saveForLater = document.getElementById('saveForLater');

  // It exists only on PE, LA, AE or Medication not Summary.
  // So if it's Summary won't save only redirect to step.
  if (saveForLater) {
    saveForLater.value = true;
    const currentStep = document.getElementById('currentStep').value;

    const redirectTo = document.getElementById('redirectTo');
    redirectTo.value = redirectURL || currentStep;

    forms[0].submit();
  } else {
    const workflowURL = document.getElementById('workflowURL');
    const nextURL = workflowURL.value.replace('WORKFLOW_URL', redirectURL);

    //window.location.href = nextURL;
    Turbolinks.visit(nextURL);
  }
};

export const check_flash_messages = () => {
  setTimeout(() => {
    save_for_later_clear_flash_messages();
  }, 3000);
};

export const header_text_input_focus = event => {
  event.stopPropagation();
  event.preventDefault();

  return false;
};

export const header_element_update_title = element => {
  const bodyContainer = element.closest(".collapsible-body");
  const headerContainer = bodyContainer.previousSibling;
  const headerTitle = headerContainer.getElementsByClassName('new-record-title')[0];
  headerTitle.innerHTML = element.value;
};

export const add_new_item = (classNameForContainer, template, functionsToLoad) => {
  const newItems = document.getElementsByClassName(classNameForContainer);
  const firstNewItem = newItems[0];
  const firstNewItemIsHide = Array.from(firstNewItem.classList).find(c => c === 'hide');

  if (firstNewItemIsHide) {
    show_DOM_element(firstNewItem);
    show_expanded_item(firstNewItem);
  } else {
    // get template and append to container for new record.
    const templateElement = document.getElementById(template.name);
    if(!templateElement) return;

    const newRecordId = getID();
    const newItemContent = build_new_item_content(newRecordId, template.identifier, classNameForContainer, templateElement.dataset.fields);

    const itemsContainer = firstNewItem.closest('ul');
    itemsContainer.insertAdjacentHTML('afterbegin', newItemContent);

    const newItem = document.getElementById(newRecordId);
    show_expanded_item(newItem);

    functionsToLoad.forEach(func => func(newRecordId));

    return newRecordId;
  }
};

export const initialize_textarea = () => {
  const textareaElements = document.querySelectorAll("textarea.materialize-textarea");

  for (let textareaElement of textareaElements) {
    M.textareaAutoResize(textareaElement);
  };
};

export const toggle_other = _event => {
  const element = _event.target.closest('label');
  const showUploadFile = element.dataset.showUploadFile;
  const optionsContainer = element.closest('.options-container');

  const currentCheckboxChecked = element.firstChild.checked;
  const currentCheckboxValue = element.children[1].innerText.toLowerCase();
  const requiredCheckVisibilityToDescribe = (currentCheckboxValue === 'other, specify' || currentCheckboxValue === 'other');

  if(requiredCheckVisibilityToDescribe) {
    let showDescribeContainer = false;
    const otherDescribeContainerByContainer = optionsContainer.getElementsByClassName('other-describe')[0];

    if(currentCheckboxChecked) {
      showDescribeContainer = true;
    }

    if(showDescribeContainer) {
      show_DOM_element(otherDescribeContainerByContainer);

      const input = otherDescribeContainerByContainer.getElementsByTagName('input')[0];
      input.focus();
    } else {
      hide_DOM_element(otherDescribeContainerByContainer);
    }
  }

  if(showUploadFile) {
    const elementCheckboxChecked = currentCheckboxChecked;
    const fileElement = element.closest('.checkbox-option-container').nextElementSibling.firstChild;

    if(elementCheckboxChecked) {
      show_DOM_element(fileElement);
    } else {
      hide_DOM_element(fileElement);
    }
  }
};

export  const set_focus_section = li => {
  const medicationSectionBody = li.closest('.entity-section-body');
  medicationSectionBody.previousSibling.getElementsByTagName('input')[0].focus();
};

export const undo_action = element => {
  const li = element.closest('li');
  const templateItem = li.getElementsByClassName('template-item')[0];

  set_focus_section(li);

  // For default there's an item by type. Initially for Workflow row We have an medication S, CM and TT, also AE and LA one
  // so We remove only template one, default one is hide.
  if(templateItem) {
    remove_DOM_element(li);
  } else {
    hide_DOM_element(li);
  }
};

export const done_action = _event => {
  const element = _event.target;
  const li = element.closest('li');

  collapse_card(li);
  set_focus_input_in_card_header(li);
};

export const collapse_card = li => {
  li.classList.remove('active');
  const body = li.getElementsByClassName('collapsible-body');
  body[0].style.display = 'none';
};

export const hide_not_item_added_message = containerClassName => {
  const container = document.getElementsByClassName(containerClassName)[0];
  const itemsList = container.closest('ul');
  const mainBody = itemsList.closest('.entity-section-body');

  const notItemAddedMessage = mainBody.getElementsByClassName('not-item-added')[0];
  if(!notItemAddedMessage) return;

  const notItemAddedMessageStyle = window.getComputedStyle(notItemAddedMessage);
  if(notItemAddedMessageStyle.display == 'block') {
    hide_DOM_element(notItemAddedMessage);
  }
};

export const toggle_date_stopped_action = _event => {
  const element = _event.target.closest('label');

  const value = element.dataset.stoppedValue;
  const dateStoppedCalendar = element.closest('.date-stopped').nextSibling;
  return value === 'No' ? show_DOM_element(dateStoppedCalendar) : hide_DOM_element(dateStoppedCalendar);
};

export const show_tab_medication_required = () => {
  const event = new CustomEvent("show_tab_medication_required");
  window.dispatchEvent(event);
};

export const hide_tab_medication_required = () => {
  const event = new CustomEvent("hide_tab_medication_required");
  window.dispatchEvent(event);
};
