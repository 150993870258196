import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "formBodyContainer",
    "addressContainer",
    "payNowContainer",
    "targetedTherapyOnlyPayeeClinic",
    "targetedTherapyOnlyPayeeParent",
    "parentContainer",
    "parentPhoneNumber",
    "parentEmail",
    "streetAddress",
    "city",
    "zip",
    "submitContainer",
    "priceContainer",
    "price",
    "priceContainer_0",
    "price_0",
    "priceContainer_1",
    "price_1",
    "priceContainer_2",
    "price_2",
    "totalPriceContainer",
    "totalPrice",
    "state",
    "drug",
    "drug_0",
    "drug_1",
    "drug_2",
    "strength",
    "strength_0",
    "strength_1",
    "strength_2",
    "quantity",
    "quantity_0",
    "quantity_1",
    "quantity_2",
    "refills",
    "refills_0",
    "refills_1",
    "refills_2",
    "instructions",
    "instructions_0",
    "instructions_1",
    "instructions_2",
  ];

  reset_form = () => {
    this.hide_target_input("formBodyContainer")
    this.hide_target_input("submitContainer")
    section.dataset.price = "0";
    return false;
  }

  update_drug_selection = async _event => {
    console.log("update drug selection")
    let section = document.getElementById('multiple_drug_sale')
    let drug = this.drugTarget.value;
    if(drug === ""){
      return this.reset_form();
    }
    if(drug === "-1"){
      M.Modal.getInstance(document.querySelector("#order-rx-modal"), {}).open();
      return this.reset_form();
    }
    let state = document.getElementById("shipping_state").value;
    console.log(state);
    let result = await this.validate_drug_can_ship_to_state(drug, state)
    console.log(result)
    if (Object.keys(result).length === 0) {
      this.show_alert("Sorry, this pharmacy cannot ship to the state selected. Please order through <a href='#order-rx-modal' class='modal-trigger'>Wedgewood Pharmacy</a> or contact <a href='mailto:operations@fidocure.com'>operations@fidocure.com.</a>")
      this.hide_target_input("formBodyContainer")
      this.hide_target_input("submitContainer")
      section.dataset.price = "0";
      return;
    }
    this.show_target_input("formBodyContainer")
    this.show_target_input("submitContainer")

    // handles the scenario of this field having an initial value. Forces javascript to play out
    if(document.getElementById("case_drug_sale_ship_to_owner_or_clinic_clinic").checked){
      document.getElementById("case_drug_sale_ship_to_owner_or_clinic_clinic").click();
    } else if(document.getElementById("case_drug_sale_ship_to_owner_or_clinic_owner").checked){
      document.getElementById("case_drug_sale_ship_to_owner_or_clinic_owner").click();
    }

    if(result.max_strength) {
      console.log(result.max_strength)
      this.strengthTarget.max = result.max_strength
    }
    this.update_price(_event)
  }

  update_multiple_drug_selection = async _event => {
    console.log("update drug selection")
    let section = _event.target.closest('.multiple_drug_sales')
    let section_id = section.dataset.sectionId;
    console.log(_event.target);
    let drug = _event.target.value;
    if (drug === "-1") {
      this.set_select_value(_event.target, "")
      M.Modal.getInstance(document.querySelector("#order-rx-modal"), {}).open();
      section.dataset.price = "0";
      this.update_price(_event)
      return false;
    }
    let state = document.getElementById("shipping_state").value;
    console.log(state);
    let result = await this.validate_drug_can_ship_to_state(drug, state)
    console.log(result)
    if (Object.keys(result).length === 0) {
      this.show_alert("Sorry, this pharmacy cannot ship to the state selected. Please order through <a href='#order-rx-modal' class='modal-trigger'>Wedgewood Pharmacy</a> or contact <a href='mailto:operations@fidocure.com'>operations@fidocure.com.</a>")
      this.set_select_value(_event.target, "")
      section.dataset.price = "0";
      this.update_price(_event)
      return false;
    }
    if(result.max_strength) {
      console.log(result.max_strength)
      this[`strength_${section_id}Target`].max = result.max_strength
    }
    this.update_price(_event)
  }

  update_price = async _event => {
    this.adjust_layout_for_lifetime();
    let section = _event.target.closest('.multiple_drug_sales');
    let section_postfix
    if(section === null){
      section = document.getElementById('multiple_drug_sale');
      section_postfix = "";
    } else {
      section_postfix = `_${section.dataset.sectionId}`;
    }
    let strength = this[`strength${section_postfix}Target`].value;
    let quantity = this[`quantity${section_postfix}Target`].value
    if(strength === "" || quantity === "" || strength === "0") {
      this.set_price(section, section_postfix, 0);
      return;
    }
    let drug = this[`drug${section_postfix}Target`].value;
    let result = await this.get_price(drug, strength, quantity)
    console.log(result)
    this.set_price(section, section_postfix, result || 0);
  }

  adjust_layout_for_lifetime = () => {
    let is_lifetime = null;
    try {
      is_lifetime = document.getElementById("case_case_information_attributes_at_cost_therapy_pricing_false").checked
    } catch (e) {
      is_lifetime = document.getElementById("case_case_information_attributes_at_cost_therapy_pricing").value === "false"
    }
    if(is_lifetime) {
      document.getElementById("drug_sale_submission_form").classList.add("lifetime")
      document.getElementsByClassName("refills_col").forEach(el=>el.classList.replace("s1", "s2"))
      document.getElementsByClassName("price_col").forEach(el=>el.classList.remove("s2"))
      document.getElementsByClassName("instructions_col").forEach(el=>el.classList.replace("s3", "s4"))
    } else {
      document.getElementById("drug_sale_submission_form").classList.remove("lifetime")
      document.getElementsByClassName("refills_col").forEach(el=>el.classList.replace("s2", "s1"))
      document.getElementsByClassName("price_col").forEach(el=>el.classList.add("s2"))
      document.getElementsByClassName("instructions_col").forEach(el=>el.classList.replace("s4", "s3"))
    }
  }

  total_price = () => {
    let sections = Array.from(document.querySelectorAll(".multiple_drug_sales, #multiple_drug_sale"));
    let prices = sections.map((currentValue, index, arr) => {return currentValue.dataset.price ? parseFloat(currentValue.dataset.price) : 0});
    return prices.reduce((accumulator, currentValue) => {return accumulator + currentValue},0) / 100;
  }

  set_price = (section, section_postfix, price) => {
    section.dataset.price = price;
    if(!price){
      this.hide_target_input(`priceContainer${section_postfix}`);
      return;
    }
    this[`price${section_postfix}Target`].innerHTML = `$${(price/100).toFixed(2)}`;

    this.show_target_input(`priceContainer${section_postfix}`)
    this.set_total_price();
  }

  set_total_price = () => {
    let total_price = this.total_price()
    if(!total_price){
      document.getElementById("drug_sale_submission_form").reportValidity()
      this.hide_target_input("totalPriceContainer");
      return;
    }
    this.totalPriceTarget.innerHTML = `$${total_price.toFixed(2)}`;

    this.show_target_input("totalPriceContainer")
  }

  validate_drug_can_ship_to_state = async (drug, state) => {
    if(!drug || !state) {
      return false;
    }
    const response = await fetch(`/drug_inventories?id=${drug}&state=${state}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
      }
    })
    const result = await response.json();
    console.log(result)
    return result;
  }

  get_price = async (drug, strength, quantity) => {
    if(!drug || !strength || !quantity) {
      return;
    }
    const response = await fetch(`/drug_inventories/price?id=${drug}&strength=${strength}&quantity=${quantity}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
      }
    })
    const result = await response.json();
    console.log(result)
    return result.price;
  }

  update_shipping_target = async _event => {
    let target = _event.target;
    console.log(target.value);
    let data = this.addressContainerTarget.dataset;
    let selected_state = document.getElementById("shipping_state").value;
    this.streetAddressTarget.value = ""
    this.cityTarget.value = ""
    this.zipTarget.value = ""

    if(target.value === "owner") {
      if(data["parentState"] !== ""){
        let keys = await this.validate_drug_can_ship_to_state(this.drugTarget.value, data["parentState"])
        if(Object.keys(keys).length === 0) {
          this.show_alert("Sorry! This drug cannot ship to the state selected.  Please either ship to clinic or contact <a href='mailto:operations@fidocure.com'>operations@fidocure.com</a> for additional guidance")
          return
        }
        this.set_select_value("state", data["parentState"])
        this.streetAddressTarget.value = data["parentStreetAddress"]
        this.cityTarget.value = data["parentCity"]
        this.zipTarget.value = data["parentZip"]
      }
    } else if (target.value === "clinic"){
      this.set_select_value("state", data["hospitalState"])
      this.streetAddressTarget.value = data["hospitalStreetAddress"]
      this.cityTarget.value = data["hospitalCity"]
      this.zipTarget.value = data["hospitalZip"]
    }
    this.show_target_input("addressContainer");
  }

  change_payee = _event => {
    let target = _event.target;
    console.log(target.value);
    if(target.value === "clinic") {
      this.make_target_input_required("targetedTherapyOnlyPayeeClinic")
      this.make_target_input_required("targetedTherapyOnlyPayeeParent")
      this.show_target_input("payNowContainer")
      this.make_target_input_unrequired("parentEmail")
      this.make_target_input_unrequired("parentPhoneNumber")
      this.hide_target_input("parentContainer")
    } else {
      this.hide_target_input("payNowContainer")
      this.make_target_input_unrequired("targetedTherapyOnlyPayeeClinic")
      this.make_target_input_unrequired("targetedTherapyOnlyPayeeParent")
      this.make_target_input_required("parentEmail")
      this.make_target_input_required("parentPhoneNumber")
      this.show_target_input("parentContainer")
    }
  }

  add_drug = _event => {
    let new_container = document.querySelector(".multiple_drug_sales.hide")
    new_container.classList.remove('hide')
    let section_id = new_container.dataset.sectionId
    this.make_target_input_required(`drug_${section_id}`)
    this.make_target_input_required(`strength_${section_id}`)
    this.make_target_input_required(`quantity_${section_id}`)
    this.make_target_input_required(`refills_${section_id}`)
    this.make_target_input_required(`instructions_${section_id}`)
    if(document.querySelector(".multiple_drug_sales.hide") === null) {
      document.querySelector(".add-other").classList.add('hide')
    }
  }

  remove_drug = _event => {
    _event.preventDefault()
    let section = _event.target.closest('.multiple_drug_sales');
    let section_id = section.dataset.sectionId;
    this.set_select_value(`drug_${section_id}`, "");
    this.make_target_input_unrequired(`drug_${section_id}`);
    this.clear_and_make_unrequired(`strength_${section_id}`);
    this.make_target_input_unrequired(`quantity_${section_id}`);
    this.set_select_value(`quantity_${section_id}`, "");
    this.clear_and_make_unrequired(`refills_${section_id}`);
    this.clear_and_make_unrequired(`instructions_${section_id}`);
    section.classList.add('hide');
    this.set_price(section, `_${section_id}`, 0);
    this.set_total_price();
  }

  submit_confirmation = _event => {
    _event.preventDefault();
    let validity = document.getElementById("drug_sale_submission_form").reportValidity();
    if(!validity) {
      return;
    }
    let selected_first_drug = this.drugTarget;
    let ship_to = document.getElementById("case_drug_sale_ship_to_owner_or_clinic_clinic").checked ? "Clinic" : "Pet Parents";
    document.querySelectorAll('#drug-sale-submission-confirmation .ship_to').forEach((el) => {el.innerHTML = ship_to});
    let address = `${this.streetAddressTarget.value} ${this.cityTarget.value}, ${this.stateTarget.value}, ${this.zipTarget.value}`;
    document.querySelectorAll('#drug-sale-submission-confirmation .shipping_address').forEach((el) => {el.innerHTML = address});
    document.querySelectorAll('#drug-sale-submission-confirmation .total_price').forEach((el) => {el.innerHTML = `$${this.total_price().toFixed(2)}`});
    let first_drug_price = document.getElementById("multiple_drug_sale").dataset.price;
    let order_rows_html = this.confirmation_drug_row(selected_first_drug.options[selected_first_drug.selectedIndex].innerHTML, this.strengthTarget.value, this.quantityTarget.value, first_drug_price, this.refillsTarget.value, this.instructionsTarget.value);
    let order_sections_html = this.confirmation_drug_section(selected_first_drug.options[selected_first_drug.selectedIndex].innerHTML, this.strengthTarget.value, this.quantityTarget.value, first_drug_price, this.refillsTarget.value, this.instructionsTarget.value);

    for (const idx of [0, 1, 2]) {
      let drug = this[`drug_${idx}Target`]
      if(!drug.value) {
        continue
      }
      let price = document.getElementById(`multiple_drug_sale_${idx}`).dataset.price;
      order_rows_html += this.confirmation_drug_row(drug.options[this[`drug_${idx}Target`].selectedIndex].innerHTML, this[`strength_${idx}Target`].value, this[`quantity_${idx}Target`].value, price, this[`refills_${idx}Target`].value, this[`instructions_${idx}Target`].value);
      order_sections_html += this.confirmation_drug_section(this[`drug_${idx}Target`].options[this[`drug_${idx}Target`].selectedIndex].innerHTML, this[`strength_${idx}Target`].value, this[`quantity_${idx}Target`].value, price, this[`refills_${idx}Target`].value, this[`instructions_${idx}Target`].value);
    }
    document.querySelector("#drug-sale-submission-confirmation .order-table").innerHTML = order_rows_html;
    document.querySelector("#drug-sale-submission-confirmation .order-sections").innerHTML = order_sections_html;
    this.adjust_layout_for_lifetime();
    M.Modal.getInstance(document.querySelector("#drug-sale-submission-confirmation"), {}).open();
  }

  confirmation_drug_row = (drug_name, strength, quantity, price, refills, instructions) => {
    price = price ? parseFloat(price) : 0
    price = `$${(price/100).toFixed(2)}`
    return `<div class="col s12 pt0_5 pb0_75"><div class="row mt1 mb0_25"><div class="col s2 pl0_75"><h4 class="mt0_25">${drug_name}</h4></div><div class="col s2 pl0"><h4 class="mt0_25">${strength}mg</h4></div><div class="col s2 pl0"><h4 class="mt0_25">${quantity}</h4></div><div class="col s1 pl0_5 refills_col"><h4 class="mt0_25">${refills}</h4></div><div class="col s2 pl0 price_col price_container"><h4 class="mt0_25">${price}</h4></div><div class="col s3 pl0 instructions_col"><h4 class="mt0_25">${instructions}</h4></div></div></div>`
  }
  confirmation_drug_section = (drug_name, strength, quantity, price, refills, instructions) => {
    price = price ? parseFloat(price) : 0
    price = `$${(price/100).toFixed(2)}`
    return `<div class="col s12"><div class="row mb0"><div class="col s12"><h3 class="mb0_25">${drug_name}</h3></div><div class="col s12 mt0_25"><div>Strength (mg): ${strength}</div><div>Quantity: ${quantity}</div><div>Refills: ${refills}</div><div class="price_container">Price: ${price}</div><div>Instructions: ${instructions}</div></div></div></div>`
  }

  show_alert = msg => {
    document.getElementById("alert_msg").innerHTML = msg;
    M.Modal.getInstance(document.querySelector("#alert-modal"), {}).open();
  }

  show_target_input = inputTargetName => {
    try {
      if (inputTargetName !== null) {
        this[inputTargetName + "Target"].classList.remove('hide');
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }

  hide_target_input = inputTargetName => {
    try {
      if (inputTargetName !== null) {
        this[inputTargetName + "Target"].classList.add('hide');
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }
  make_target_input_required = inputTargetName => {
    try {
      if (inputTargetName !== null) {
        this[inputTargetName + "Target"].required = true;
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }

  make_target_input_unrequired = inputTargetName => {
    try {
      if (inputTargetName !== null) {
        this[inputTargetName + "Target"].required = false;
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }

  clear_and_make_unrequired = inputTargetName => {
    this.make_target_input_unrequired(inputTargetName)
    try {
      if (inputTargetName !== null) {
        this[inputTargetName + "Target"].value = "";
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }

  set_select_value = (inputTargetName, value) => {
    let elem;
    if(typeof(inputTargetName) === "string"){
      elem = this[inputTargetName + "Target"];
    } else {
      elem = inputTargetName
    }
    elem.value = value;
    let options = M.FormSelect.getInstance(elem)
    M.FormSelect.init(elem, options)
  }

  slow_show_target_input = inputTargetName => {
    try {
      if (inputTargetName !== null) {
        this[inputTargetName + "Target"].classList.remove('slow_hide');
        this[inputTargetName + "Target"].classList.add('slow_show');
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }

  slow_hide_target_input = inputTargetName => {
    try {
      if (inputTargetName !== null) {
        this[inputTargetName + "Target"].classList.remove('slow_show');
        this[inputTargetName + "Target"].classList.add('slow_hide');
      }
    } catch (error) {console.log("No element named " + inputTargetName)}
  }

}
