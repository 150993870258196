// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus"
import {
  show_DOM_element,
  hide_DOM_element,
  check_flash_messages,
  initialize_textarea,
  add_new_item,
  collapse_card,
  hide_not_item_added_message,
  toggle_date_stopped_action,
  header_text_input_focus,
  header_element_update_title
} from "./outcomes-lib";

export default class extends Controller {
  static targets = [
    'medicationRequiredMark',
    'physicalExamWeight'
  ];

  initialize() {}

  connect() {
    console.log('Hello, Stimulus from outcomes > Medication > controller!');
    check_flash_messages();
    initialize_textarea();
  };

  reload_data_due_new_medication = medicationId => {
    const mainContainer =  document.getElementById(`${medicationId}`);
    const autocompleteElement = mainContainer.getElementsByClassName('autocomplete')[0];
    const dataJSON = JSON.parse(autocompleteElement.getAttribute("data-autocomplete"));

    const items = [];
    const tags = [];
    let i;

    if(dataJSON) {
      for (let i = 0; i < dataJSON.length; ++i) {
        items[dataJSON[i]] = null;
        tags.push({ tag: dataJSON[i] });
      }
    }

    M.Autocomplete.init(autocompleteElement, { data: items, minLength: 2 });

    const selectElement = mainContainer.getElementsByTagName('select')[0];
    M.FormSelect.init(selectElement);
  };

  add_medication = _event => {
    const element = _event.target.closest('.add-other');
    const classNameForContainer = element.dataset.medicationContainerClassName;
    const templateIdentifier = element.dataset.medicationTemplateIdentifier;
    const templateName = element.dataset.medicationTemplateName;

    hide_not_item_added_message(classNameForContainer);

    const functionsToLoad = [this.reload_data_due_new_medication];
    const template = {
      identifier: templateIdentifier,
      name: templateName
    };
    add_new_item(classNameForContainer, template, functionsToLoad);
  };

  target_therapies_dose_calculation = _event => {
    try {
      const element = _event.target;
      let dosage = element.value;
      let weight = this.physicalExamWeightTarget.value;

      if(!weight || !dosage) return;

      dosage = parseFloat(dosage);
      weight = parseFloat(weight);

      const calculation = element.closest('.dosage-container').nextElementSibling.querySelector('span');
      calculation.innerHTML = Number(weight/dosage).toFixed(2);
    } catch (err) {
      console.log(err);
    }
  };

  toggle_dosage_changed_section = _event => {
    const element = _event.target.closest('label');
    const show = element.dataset.isChanged;
    const dosageChangedDetailsSection = element.closest('.medication-dosage-changed-options-container').nextElementSibling;

    return show === "true" ? show_DOM_element(dosageChangedDetailsSection) : hide_DOM_element(dosageChangedDetailsSection);
  };

  medication_view_done = _event => {
    const element = _event.target;
    const li = element.closest('li');
    collapse_card(li);

    const header = element.closest('.entity-section-body').previousSibling;
    const firstInput = header.getElementsByTagName('input')[0];

    firstInput.focus();
  };

  toggle_date_stopped = _event => {
    toggle_date_stopped_action(_event);
  };

  header_text_focus(_event) {
    header_text_input_focus(_event);
  };

  header_update_title(_event) {
    const element = _event.target;
    header_element_update_title(element);
  };

  show_medication_tab_required() {
    show_DOM_element(this.medicationRequiredMarkTarget);
  }

  hide_medication_tab_required() {
    hide_DOM_element(this.medicationRequiredMarkTarget);
  }
}
