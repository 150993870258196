// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus"
import {
  hide_element,
  show_element,
  show_DOM_element,
  check_flash_messages,
  add_new_item,
  hide_DOM_element,
  initialize_textarea,
  undo_action,
  done_action,
  header_text_input_focus,
  header_element_update_title,
  submit_save_later
} from "./outcomes-lib";


export default class extends Controller {
  static targets = [
    'targetLesionContainer',
    'lesionAssessmentNotReason',
    'notTargetLesionContainer'
  ];

  initialize() {}

  connect() {
    console.log('Hello, Stimulus from outcomes > LA > controller!');
    check_flash_messages();
    initialize_textarea();
  }

  LA_TYPES = {
    TARGET_LESION: "target_lesion",
    NON_TARGET_LESION: "non_target_lesion",
    NEW_LESION: "new_lesion"
  };

  update_clinical_response(hasNewLesion) {
    const options = document.querySelectorAll('#clinicalResponseOptionsContainer input[type = radio]');
    const completeResponseOption = options[0];
    const partialResponseOption = options[1];
    const stableDiseaseOption = options[2];
    const progressiveDiseaseOption = options[3];

    completeResponseOption.disabled = hasNewLesion;
    partialResponseOption.disabled = hasNewLesion;
    stableDiseaseOption.disabled = hasNewLesion;

    progressiveDiseaseOption.checked = hasNewLesion;
  };

  show_not_lesion_reason() {
    hide_element('lesionAssessmentFieldsForm');

    const lesionAssessmentNotReasonContainer = this.lesionAssessmentNotReasonTarget;
    const input = lesionAssessmentNotReasonContainer.getElementsByTagName('textarea')[0];

    show_DOM_element(lesionAssessmentNotReasonContainer);

    input.focus();
  };

  show_lesion_fields_form() {
    hide_element('lesionAssessmentNotReason');
    show_element('lesionAssessmentFieldsForm');
  };

  exist_new_lesion_adding() {
    const defaultNewLesionItem = document.querySelectorAll('#newLesionContainer li')[0];
    const showing = Array.from(defaultNewLesionItem.classList).find(styleClass => styleClass === 'show');

    return showing;
  };

  check_add_record_available(type) {
    const MAX = 5;
    let container;
    let itemsCount = 0;

    if(type === this.LA_TYPES.TARGET_LESION) {
      container = this.targetLesionContainerTarget;
      const itemsAdding = container.querySelectorAll('ul .entity-new-item');
      itemsCount = itemsAdding.length;

    } else {
      container = this.notTargetLesionContainerTarget;
      const itemsAdding = container.querySelectorAll('ul .entity-new-item');

      const previousItems = document.querySelectorAll('#notTargetLesionPreviousContainer ul li');
      const previousItemsCount = previousItems ? previousItems.length : 0;

      itemsCount = previousItemsCount + itemsAdding.length;
    }

    const addRecordContainer = container.previousElementSibling;

    if (itemsCount >= MAX) {
      hide_DOM_element(addRecordContainer);
    } else {
      show_DOM_element(addRecordContainer);
    }
  };

  set_record_number(record) {
    let number;
    const records =  this.targetLesionContainerTarget.querySelectorAll('.target-card li.entity-new-item');
    const recordsItem = records.length;

    if(recordsItem === 1) {
      number = records[0].getElementsByClassName('record-number')[0];
    } else {
      number = document.getElementById(`${record}`).getElementsByClassName('record-number')[0];
    }

    number.innerText = recordsItem;
  };

  reload_data_for_new_LA_record_by_template(newRecordId) {
    const newRecord = document.getElementById(newRecordId);
    const methodCodeSelect = newRecord.querySelector('select');

    M.FormSelect.init(methodCodeSelect);
  };

  undo_for_LA(_event) {
    const element = _event.target.closest('a');
    const type = element.dataset.targetType;

    undo_action(element);

    switch(type) {
      case this.LA_TYPES.TARGET_LESION:
        this.check_add_record_available(this.LA_TYPES.TARGET_LESION);
        break;

      case this.LA_TYPES.NON_TARGET_LESION:
        this.check_add_record_available(this.LA_TYPES.NON_TARGET_LESION);
        break;

      case this.LA_TYPES.NEW_LESION:
        const hasNewLesion = exist_new_lesion_adding();
        this.update_clinical_response(hasNewLesion);

      default:
        break;
    }
  };

  add_target_lesion() {
    const functionsToLoad = [this.reload_data_for_new_LA_record_by_template];

    const template = {
      identifier: 'new_record_TL',
      name: 'targetLesionTemplate'
    };

    const recordId = add_new_item('target-lesion-item-section', template, functionsToLoad);
    this.check_add_record_available(this.LA_TYPES.TARGET_LESION);

    this.set_record_number(recordId);
  };

  add_non_target_lesion() {
    const functionsToLoad = [this.reload_data_for_new_LA_record_by_template];

    const template = {
      identifier: 'new_record_NTL',
      name: 'nonTargetLesionTemplate'
    };

    add_new_item('non-target-lesion-item-section', template, functionsToLoad);
    this.check_add_record_available(this.LA_TYPES.NON_TARGET_LESION);
  };

  add_new_lesion() {
    const functionsToLoad = [];

    const template = {
      identifier: 'new_record_new_lesion',
      name: 'newLesionTemplate'
    };

    add_new_item('new-lesion-item-section', template, functionsToLoad);
    this.update_clinical_response(true);
  };

  select_check_status_for_non_lesion(_event) {
    const element = _event.target.closest('label');
    const currentRadio = element.children[0];
    const progressiveComments = element.closest('.non-target-lesion-options').getElementsByClassName('comments-section')[0];

    if(currentRadio.value === 'progressive') {
      show_DOM_element(progressiveComments);
      const textareaElement = progressiveComments.querySelector('textarea');

      if(textareaElement) {
        textareaElement.focus();
      }
    } else {
      hide_DOM_element(progressiveComments);
    }
  };

  hide_change_options(_event) {
    const element = _event.target.closest('.changes-options-container').nextSibling;
    hide_DOM_element(element);
  };

  show_change_options(_event) {
    const element = _event.target.closest('.changes-options-container').nextSibling;
    show_DOM_element(element);
  };

  done(_event) {
    // done_action(_event);
    return submit_save_later();
  };

  header_text_focus(_event) {
    header_text_input_focus(_event);
  };

  header_update_title(_event) {
    const element = _event.target;
    header_element_update_title(element);
  };
}
