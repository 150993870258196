import { _refinedDisjunctiveFacets, _isRefinedOrQuerried, _showHideTargetElementOnQuerryOrRefined } from './common.js'
function _years_months(months) {
  if (months == -1) {
    return `n/a`;
  }
  const years = months < 12 ? 0 : Math.floor(months / 12);
  const remainder = months - years * 12;
  const str =
    years == 0
      ? `${remainder} months old`
      : `${years} years and ${remainder} months old`;
  return str;
}

function _na_for_numerics(value) {
  if (value == -1) {
    return `n/a`;
  } else {
    return value;
  }
}

function _each_gene_mutation(gene_mutations) {
  return gene_mutations
    .map((el) => `<div class="col s6 gene-mutation">${el}</div>`)
    .join("");
}

// Reponsible of Rendering Results Widget with Infinite Scroll:
export function resultsWidget(hitsElementId, statsElementId) {
  let readyToFetchMore = true;

  function _renderResultsItem(item) {
    // console.log(item);
    return `<li class="ais-Hits-item" onclick="javascript:window.open('/admin/cases/${
      item.air_record_id
    }?case_id=${item.case_id}','_blank'); return false;">
              <div class="row mb0">
                <div class="col s4">
                  <div class="truncate case-id">
                    ${item._highlightResult.case_id.value}
                  </div>
                  <div class="truncate">
                    ${item._highlightResult.patient_status.value}
                  </div>
                </div>
                <div class="col s4">
                  <div class="truncate">
                    ${item._highlightResult.disease_confirmation_normalized.value}
                  </div>
                  <div class="truncate">
                    ${item._highlightResult.pet_breed.value}
                  </div>
                  <div class="truncate">
                    ${_years_months(item.pet_dob_months)}
                  </div>
                </div>
                <div class="col s4 pl2">
                  <div class="truncate">
                    ${_na_for_numerics(
                      item.survival_time
                    )} <small class='fc-portal-grey-1-text'>days survival</small>
                  </div>
                  <div class="truncate">
                    ${_na_for_numerics(
                      item.total_days_on_fc_drugs
                    )} <small class='fc-portal-grey-1-text'>days on Rx</small>
                  </div>
                  <div class="truncate">
                    ${_na_for_numerics(
                      item.case_pre_fido_survival_time
                    )} <small class='fc-portal-grey-1-text'> days pre fido survival</small>
                  </div>
                </div>
              </div>
              <div class="row mb0">
                ${_each_gene_mutation(
                  item.dna_genes_actionable_expression_mutation_and_protein
                )}
              </div>
            </li>`;
  }

  function _appendHitsToResults(hits, page, hitsElementId) {
    const html =
      hits.length > 0
        ? `${hits.map((item) => _renderResultsItem(item)).join("")}`
        : ``;

    if (page == 0) {
      document.querySelector(hitsElementId).innerHTML = html;
    } else {
      document.querySelector(hitsElementId).innerHTML =
        document.querySelector("#hits").innerHTML + html;
    }
  }

  function _renderStats(state, nbHits, statsElementId) {
    if (!_isRefinedOrQuerried(state)) {
      document.querySelector(statsElementId).innerHTML = "&nbsp;";
    } else if (nbHits == 0) {
      document.querySelector(statsElementId).innerHTML = "no results.";
    } else {
      document.querySelector(
        statsElementId
      ).innerHTML = `<span class='semi-bold'>${nbHits}</span> matching cases.`;
    }
  }

  return {
    init: function (params) {
      // store AlgoliaSearchHelper for future:
      const helper = params.helper;
      helper.setQueryParameter("hitsPerPage", 50);

      // turn on infinite scroll if given as option
      function scrollhandler() {
        const isAtBottomOfPage =
          window.scrollY + window.innerHeight >
          document.body.scrollHeight - 700;
        if (readyToFetchMore && isAtBottomOfPage) {
          readyToFetchMore = false;
          helper.nextPage().search();
        }
      }
      window.addEventListener("scroll", scrollhandler);
    },

    render: function (params) {
      const results = params.results;
      const state = params.state;

      // render stats
      _renderStats(state, results.nbHits, statsElementId);

      // render results
      _appendHitsToResults(results.hits, results.page, hitsElementId);

      // allow it to search more (do it after rendering results since it will change the window hight)
      readyToFetchMore = results.nbHits > results.page * state.hitsPerPage;
    },
  };
}
