import { Application, Controller } from "stimulus";
import { CasesSearch } from "../../search_cases/cases.js"
export default class extends Controller {
  //static targets = ["result"];

  initialize() {
    let el = document.getElementById("no-lifetime-modal");
    if(el) {
      setTimeout(function(){M.Modal.getInstance(el, {}).open();}, 500)
    }
  }

  connect() {
    //console.log('this is algolia controller');
    const algoliaApp = this.element.dataset.algoliaApp;
    const algoliaIndex = this.element.dataset.algoliaIndex;
    const algoliaKey = this.element.dataset.algoliaKey;
    CasesSearch(algoliaIndex, algoliaApp, algoliaKey);
  };
}
