// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus"
import { submit_save_later, STEPS } from "./outcomes-lib";

export default class extends Controller {
  static targets = [
    'clearForm',
    'currentView',
    'workflowURL'
  ];

  initialize() {}

  connect() {
    console.log('Hello, Stimulus from outcomes > navigation > controller!');
  }

  back() {
    const currentStep = document.querySelector('.update-tabs #currentStep').value;
    const currentStepIndex = STEPS.indexOf(currentStep);

    const previousIndex = currentStepIndex - 1;
    const previousStep = STEPS[previousIndex];

    submit_save_later(previousStep);
  };

  submit_to_save() {
    return submit_save_later();
  }

  clear_current_form = () => {
    const workflowURL = this.workflowURLTarget;
    const currentView = this.currentViewTarget;

    const clearFormURL = workflowURL.value.replace('WORKFLOW_VIEW', currentView.value);
    window.location.href = clearFormURL;
  }
}
