const algoliasearch = require('algoliasearch/lite');
const instantsearch = require('instantsearch.js').default;
import { searchBox, refinementList, rangeSlider } from 'instantsearch.js/es/widgets';
import { resultsWidget } from './results.js'
import { saveWidget } from './save.js'
import { statsWidget } from './stats.js'
export function KmSearch(indexName, app, key)  {

  const client = algoliasearch(app, key);
  const search = instantsearch({
    indexName: indexName,
    searchClient: client,
    routing: true,
  });

  const _rendertItem = (item) => {
    return `<h6 class="mb0 mt0">${item.label} (${item.count})</h6>`;
  }

  const addRefinementList = (filter) => {
    search.addWidget(
      refinementList({
        container: '#' + filter,
        attribute: filter,
        limit: 50,
        searchable: true,
        searchablePlaceholder: 'Search...',
        searchableIsAlwaysActive: false,
        sortBy: ["name:asc"],
        operator: 'or',
        cssClasses: {
          list: 'p1',
          item: 'mt0_25 mb0_25',
        },
        templates: {
          item: _rendertItem
        }
      })
    );
  }

  const filters = ['disease_confirmation_normalized', 'visceral', 'patient_status', 'ordered_drugs', 'drugs_ordered', 'current_cancer_medications','previous_cancer_treatments','targeted_therapy_concurrent_with_chemo', 'dna_genes_actionable', 'dna_genes_actionable_expression_mutation_and_protein', 'dna_genes_other', 'dna_genes_other_expression_mutation_and_protein', 'pet_breed'];
  for (let filter of filters) {
    addRefinementList(filter)
  }

  const addRangeSlider = (range_slider) => {
    search.addWidget(
      rangeSlider({
        container: '#' + range_slider,
        attribute: range_slider,
        steps: 5,
        pips: true
      })
    );
  }

  const range_sliders = ['survival_time', 'total_days_on_fc_drugs', 'case_pre_fido_survival_time']
  for (let range_slider of range_sliders) {
    addRangeSlider(range_slider)
  }

  search.addWidget(
    resultsWidget('#hits', '#stats')
  );

  search.addWidget(
    saveWidget()
  );

  search.addWidget(
    statsWidget('#reset-search')
  );

  search.addWidgets([
   searchBox({
     container: "#searchbox",
   })
  ]);

  search.start();
}
