// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'howDidYouHearOther',
    'hospitalState',
  ];

  initialize() {
  }

  connect() {
  }

  update_country = async _event => {
    const select = _event.target;
    const response = await fetch("/utils/find_states", {
      method: 'post',
      body: JSON.stringify({'country_id': select.value}),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
      }
    })
    const states = await response.json();

    let select_instance = M.FormSelect.getInstance(this.hospitalStateTarget);
    select_instance.destroy();
    let states_el = document.getElementById('user_hospital_state');
    states_el.innerHTML = states.map((st) => {return `<option value="${st[0]}">${st[1]}</option>`});
    let instances = M.FormSelect.init(states_el, {dropdownOptions: states});
  }

  update_how_did_you_hear = _event => {
    const select = _event.target;
    this.hide_target_input(this.howDidYouHearOtherTarget);
    this.make_target_input_unrequired(this.howDidYouHearOtherTarget.querySelector('input'));

    if (select.value === 'Other' ) {
      this.show_target_input(this.howDidYouHearOtherTarget);
      this.make_target_input_required(this.howDidYouHearOtherTarget.querySelector('input'));
    }
  }

  make_target_input_required = inputTarget => {
    if (inputTarget !== null) {
      inputTarget.required = true;
    }
  }

  make_target_input_unrequired = inputTarget => {
    if (inputTarget !== null) {
      inputTarget.required = false;
    }
  }

  show_target_input = inputTarget => {
    if (inputTarget !== null) {
      inputTarget.classList.remove('hide');
    }
  }

  hide_target_input = inputTarget => {
    if (inputTarget !== null) {
      inputTarget.classList.add('hide');
    }
  }
}
