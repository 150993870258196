require("./materialize.min.js")
const initialize_form_elements = () => {
  const elems1 = document.querySelectorAll("select");
  const instances1 = M.FormSelect.init(elems1, {});

  const elems2 = document.querySelectorAll(".datepicker");
  for (let element of elems2) {
    const date = element.dataset.defaultDate;
    if (date !== undefined) {
      M.Datepicker.init(element, { defaultDate: new Date(date), yearRange: 15 });
    } else {
      M.Datepicker.init(element, {});
    }
  }

  const textareaElements = document.querySelectorAll("textarea.materialize-textarea");
  for (let textareaElement of textareaElements) {
    textareaElement.style.height = "2.75rem";
  }
};

let feature_instances;
const open_feature = (num) => {
  feature_instances[num].open();
};
const initialize_feature = () => {
  const elems = document.querySelectorAll(".tap-target");
  feature_instances = M.TapTarget.init(elems, {});
};
const initialize_autocomplete = () => {
  const elems3 = document.querySelectorAll(".autocomplete");
  elems3.forEach(function (elem, index) {
    //M.Autocomplete.init(elem, {data: elem.getAttribute('data-autocomplete')})
    initialize_auto_element(elem);
  });

  // Initialize example autocomplete:
  const exampleElems = document.querySelectorAll(".example-autocomplete");
  const data = {
    Apple: null,
    "Apple iPhone": null,
    "Apple iPad": null,
    Microsoft: null,
    Google: "https://placehold.it/250x250",
  };
  exampleElems.forEach((elem, i) => M.Autocomplete.init(elem, { data }));
};
const initialize_auto_element = (elem) => {
  const dataJSON = JSON.parse(elem.getAttribute("data-autocomplete"));
  // Prepare array for items and add each
  var items = [];
  var tags = [];
  var i;

  if (dataJSON) {
    for (i = 0; i < dataJSON.length; ++i) {
      items[dataJSON[i]] = null;
      tags.push({ tag: dataJSON[i] });
    }
  }

  M.Autocomplete.init(elem, { data: items, minLength: 2 });
};
const init_toasts = () => {
  const elems = document.querySelectorAll(".toast");
  for (let element of elems) {
    const html = element.dataset.html;
    if (html !== undefined) {
      M.toast({ html: html, classes: element.dataset.cssClass });
    }
  }
};
const initialize_mbox = () => {
  const elems = document.querySelectorAll(".materialboxed");
  const instances = M.Materialbox.init(elems, {});
};
const initialize_modals = () => {
  const elems = document.querySelectorAll(".modal");
  const instances = M.Modal.init(elems, {
    dismissible: true,
  });
};
const initialize_collapsibles = () => {
  const elem = document.querySelectorAll(".collapsible");
  const instance = M.Collapsible.init(elem, {
    accordion: false,
  });
};
const initialize_nav = () => {
  const elems = document.querySelectorAll(".sidenav");
  const instances = M.Sidenav.init(elems, {});
};
const initialize_dropdown = () => {
  const elems = document.querySelectorAll(".dropdown-trigger-hover");
  const instances = M.Dropdown.init(elems, { hover: true });
};
const initialize_tooltip = () => {
  const elems = document.querySelectorAll(".tooltipped");
  const instances = M.Tooltip.init(elems, {});
};

const initialize_shader = () => {
  const navbar = document.querySelector(".portal-nav");

  window.onscroll = function () {
    if (window.pageYOffset > 0) {
      navbar && navbar.classList.add("scrolled");
    } else {
      navbar && navbar.classList.remove("scrolled");
    }
  };
}

export function styleGuideSetup() {
  if(!document.documentElement.hasAttribute("data-turbolinks-preview")) {
    initialize_form_elements();
    initialize_nav();
    initialize_dropdown();
    initialize_feature();
    initialize_autocomplete();
    init_toasts();
    initialize_collapsibles();
    initialize_mbox();
    initialize_modals();
    initialize_tooltip();
    initialize_shader();
  }
};
