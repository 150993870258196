const algoliasearch = require('algoliasearch/lite');
const instantsearch = require('instantsearch.js').default;
import { searchBox, refinementList } from 'instantsearch.js/es/widgets';
import { resultsWidget } from './results.js'
import { statsWidget } from './stats.js'
export function CasesSearch(indexName, app, key)  {

  const client = algoliasearch(app, key);
  const search = instantsearch({
    indexName: indexName,
    searchClient: client
  });
  // const search = instantsearch({
  //   indexName: indexName,
  //   searchClient: client,
  //   routing: true,
  // });

  let priorOptions = {};
  const _rendertItem = (item, priorOptions) => {
    const zeroDisabled =
      !item.isRefined && item.count === 0 ? "zero-disabled" : "";
    return `<span class=${zeroDisabled}> ${item.label.split("_").join(" ")} (${
      item.count
    }) </span>`;
  };
  // NOTE: With the current implementation things will break if options across filters have the same name.
  const filtersAndOrderedOptions = [
    {
      to_do: [
        "Submit Tissue",
        "Submit Slides",
        "Review Report",
        "Order Rx",
        "Update Patient",
      ],
    },
    {
      all_statuses_titleized: [
        "Requesting Samples",
        "Awaiting Samples",
        "Sequencing DNA",
        "Generating Report",
        "Report Ready",
        "Patient On Therapy",
        "Draft",
        "Transferred",
        "Inactive"
      ],
    },
  ];
  const addRefinementList = (filter, ordered_options, priorOptions) => {
    search.addWidget(
      refinementList({
        container: "#" + filter,
        attribute: filter,
        limit: 15,
        sortBy: ["count:desc"],
        operator: "or",
        templates: {
          item: _rendertItem,
        },
        transformItems: (items) => {
          // This function allows us to set the order of facet options and display zeroed facets.  It is a hack but implemented in alignment with Algolia's current recommendations: https://www.algolia.com/doc/guides/building-search-ui/widgets/customize-an-existing-widget/js/#display-facets-with-no-matches.  The general idea is that Algolia only hands down information about refinement options that are associated with more than zero cases OR which initially had more than zero cases and the user refined.  To get around this general limitation we are saving the prior state of the facets in priorOptions and comparing it to what Algolia is currently handing down.  The following logic does this comparison and updates the saved state.  In the future, results and priorOptions can be combined.
          let priorOption, isRefinedBool;
          let results = [];
          ordered_options.forEach((option) => {
            const updatedItem = items.find((item) => item.value === option);
            if (updatedItem != undefined) {
              priorOptions[updatedItem.value] = updatedItem;
              results.push(updatedItem);
            } else {
              // Algolia did not hand down refinement information about this option so we look at the prior state stored in priorOptions
              priorOption = priorOptions[option];
              if (priorOption != undefined) {
                // The only way we hit the following `if`` is if someone is clicking off the option they previousely selected
                if (priorOption.isRefined == true && priorOption.count == 0) {
                  isRefinedBool = false;
                } else {
                  // This is the tricky case.  It is essential to make facets with zero counts AND unrefined, unclickable.  This is done in the _renderItem function defined above
                  isRefinedBool = priorOption.isRefined;
                }
              } else {
                // The priorOption being undefined means that Algolia didn't initially hand it down its facet value is zero.  This is the initial load case.
                isRefinedBool = false;
              }
              let newOption = {
                count: 0,
                isRefined: isRefinedBool,
                label: option,
                value: option,
                highlighted: option,
              };
              priorOptions[option] = newOption;
              results.push(newOption);
            }
          });
          return results;
        },
      })
    );
  };

  filtersAndOrderedOptions.forEach((filterAndOrderedOptions) => {
    let filter = Object.keys(filterAndOrderedOptions)[0];
    let ordered_options = filterAndOrderedOptions[filter];
    addRefinementList(filter, ordered_options, priorOptions);
  });

  search.addWidget(resultsWidget("#cases-hits", "#stats"));

  search.addWidget(statsWidget("#reset-search"));

  search.addWidgets([
    searchBox({
      container: "#searchbox",
      placeholder: "Patient or Veterinarian Name",
    }),
  ]);

  search.start();
}
