// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus";
export default class extends Controller {
  static targets = [];

  initialize() {}

  connect() {}

  redirect_to_all_cases = _event => {
    //throw new Error('Exception message');
    Turbolinks.visit('/cases', { action: 'replace'});
  }
}
