// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus"
import {
  show_element,
  show_DOM_element,
  hide_element,
  hide_DOM_element,
  check_flash_messages,
  header_text_input_focus,
  toggle_other,
  set_focus_input_in_card_header,
  set_focus_section,
  header_element_update_title,
  undo_action,
  done_action,
  submit_save_later
} from "./outcomes-lib";

export default class extends Controller {
  static targets = [];

  initialize() {}

  connect() {
    console.log('Hello, Stimulus from outcomes > done/undo > controller!');
    check_flash_messages();
  }

  done(_event) {
    //done_action(_event);
    return submit_save_later();
  };

  undo = _event => {
    const element = _event.target;
    undo_action(element);
  };
}
