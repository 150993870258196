// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus"
import { show_element, check_flash_messages,  show_tab_medication_required } from "./outcomes-lib";

export default class extends Controller {
  static targets = [
    'summarySubmitButton',
    'summaryVeterinarianInitials',
    'summaryCompletedBy',
    'summaryDate',
    'allInfoReviewed',
    'internalSubmission',
    'summaryValidations'
  ];

  initialize() {}

  connect() {
    console.log('Hello, Stimulus from outcomes > Summary > controller!');
    this.check_summary_validations();
    this.check_submit_visibility_button();
    check_flash_messages();
  }

  check_submit_visibility_button() {
    const summarySubmitButton = this.summarySubmitButtonTarget;
    if (!summarySubmitButton) return;

    const summaryVeterinarianInitials = this.summaryVeterinarianInitialsTarget;
    const summaryCompletedBy = this.summaryCompletedByTarget;
    const summaryDate = this.summaryDateTarget;
    const allInfoReviewed = this.allInfoReviewedTarget;
    const internalSubmission = this.internalSubmissionTarget;

    const isSubmitButtonEnable = (summaryVeterinarianInitials && summaryVeterinarianInitials.value && summaryDate && summaryDate.value && allInfoReviewed && allInfoReviewed.checked && summaryCompletedBy && summaryCompletedBy.value) || (internalSubmission && internalSubmission.checked);

    if (isSubmitButtonEnable) {
      summarySubmitButton.classList.remove('disabled');
    } else {
      summarySubmitButton.classList.add('disabled');
    }
  };

  check_summary_validations() {
    const summaryValidations =  this.summaryValidationsTargets[0];
    if(!summaryValidations) return;

    const allInfoReviewed = this.allInfoReviewedTarget;
    allInfoReviewed.checked = true;

    const validationsSteps = summaryValidations.getElementsByClassName('validations-step');
    const validationsStepsList = Array.from(validationsSteps);

    if(validationsStepsList.length <= 0) return;

    const STEPS = {
      PHYSICAL_EXAM: 'physical_exam',
      BODY_SYSTEM_EXAMS: 'body_system_exams',
      TARGET_LESION: 'target_lesions',
      NON_TARGET_LESION: 'non_target_lesions',
      ADVERSE_EVENTS: 'adverse_events',
      MEDICATIONS: 'medications'
    };

    if(validationsStepsList && validationsStepsList.length > 0) {
      const physicalExam = validationsStepsList.find(vs=> vs.innerHTML === STEPS.PHYSICAL_EXAM);
      const bodySystemExams = validationsStepsList.find(vs=> vs.innerHTML === STEPS.BODY_SYSTEM_EXAMS);
      if(physicalExam || bodySystemExams) {
        show_element('physicalExamRequiredMark');
      }

      const targetLesion = validationsStepsList.find(vs=> vs.innerHTML === STEPS.TARGET_LESION);
      const noTargetLesion = validationsStepsList.find(vs=> vs.innerHTML === STEPS.NON_TARGET_LESION);
      if(targetLesion || noTargetLesion) {
        show_element('lesionAssessmentMark');
      }

      const adverseEvent = validationsStepsList.find(vs=> vs.innerHTML === STEPS.ADVERSE_EVENTS);
      if(adverseEvent) {
        show_element('adverseEventRequiredMark');
      }

      const medications = validationsStepsList.find(vs=> vs.innerHTML === STEPS.MEDICATIONS);
      if(medications) {
        show_tab_medication_required();
      }
    }
  };
}
