import { _refinedDisjunctiveFacets, _isRefinedOrQuerried, _showHideTargetElementOnQuerryOrRefined, noResultsTemplate } from './common.js'
// Reponsible of Rendering Results Widget with Infinite Scroll:
export function resultsWidget(hitsElementId, statsElementId) {
  let readyToFetchMore = true;

  // show search and filters only if results (hits)
  const _showIfCasesPresent = (nbHits) => {
    const hideIfNoCases = document.querySelectorAll(".show-if-cases-present");
    nbHits > 0 &&
      hideIfNoCases.forEach((item) => item.classList.remove("hide"));
  };

  function _renderStats(state, nbHits) {
    if (document.querySelector(statsElementId)===null) { return }
    if (!_isRefinedOrQuerried(state)) {
      document.querySelector(statsElementId) &&
        (document.querySelector(statsElementId).innerHTML = `&nbsp;`);
    } else if (nbHits == 0) {
      document.querySelector(statsElementId).innerHTML = `&nbsp;`;
    } else {
      document.querySelector(statsElementId).innerHTML = `Showing ${nbHits} Results`;
    }
  }

  function _appendHitsToResults({ hits, nbHits, page }) {
    const hitsElement = document.querySelector(hitsElementId);
    if (hitsElement === null) { return }
    const html =
      nbHits > 0 ? `${hits.map((item) => item.case_template).join("")}` : ``;

    if (page == 0) hitsElement.innerHTML = html;
    else hitsElement.innerHTML = hitsElement.innerHTML + html;
  }

  function _showNoResultsPage({ nbHits, query }) {
    const hitsElement = document.querySelector(hitsElementId);
    if (nbHits === 0 && query) {
      hitsElement.innerHTML = noResultsTemplate(query);
    }
  }

  return {
    init: function (params) {
      const helper = params.helper;
      helper.setQueryParameter("hitsPerPage", 25);

      // turn on infinite scroll if given as option
      function scrollhandler() {
        const isAtBottomOfPage =
          window.scrollY + window.innerHeight >
          document.body.scrollHeight - 700;
        if (readyToFetchMore && isAtBottomOfPage) {
          readyToFetchMore = false;
          helper.nextPage().search();
        }
      }
      window.addEventListener("scroll", scrollhandler);
    },

    render: function ({ results, state }) {
      // render stats
      _renderStats(state, results.nbHits);

      // show search and filters only if results (hits)
      _showIfCasesPresent(results.nbHits);

      // Append results if refined or querried
      if (_isRefinedOrQuerried(state)) {
        _appendHitsToResults(results);
        _showNoResultsPage(results);
      }

      // allow it to search more (do it after rendering results since it will change the window hight)
      readyToFetchMore = results.nbHits > results.page * state.hitsPerPage;
    },
  };
}
