require("./materialize.min.js")

const destroy_form_elements = () => {
    const selects = document.querySelectorAll("select");

    selects.forEach(function (element) {
        const instance = M.FormSelect.getInstance(element);
        if(instance) {
            instance.destroy();
        }
    });
};

const destroy_modals = () => {
    const modals = document.querySelectorAll(".modal");

    modals.forEach(function (element) {
        const instance = M.Modal.getInstance(element);

        if(instance) {
            instance.destroy();
        }
    });
};

const destroy_sidenav = () => {
    const sidenav = document.getElementsByClassName("sidenav")[0];

    if(sidenav) {
      const sideNavInstance= M.Sidenav.getInstance(sidenav);

      if(sideNavInstance) {
        sideNavInstance.destroy();
      }
    }
};

const destroy_dropdown = () => {
    const dropdown = document.querySelectorAll(".dropdown-trigger-hover");

    dropdown.forEach(function (element) {
        const instance = M.Dropdown.getInstance(element);

        if(instance) {
            instance.destroy();
        }
    });
};
export function styleGuideTeardown() {
  destroy_dropdown();
  destroy_form_elements();
  destroy_modals();
  destroy_sidenav();
};
