require("../../styleguide/materialize.min.js");
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'form',
    'submitButton',
    'spinner'
  ];

  initialize() {}

  connect() {}

  checkFormValidity = () => {
    if (this.formTarget.checkValidity() === false) {
      this.formTarget.reportValidity();
      return false;
    }
    return true;
  }

  openSpinner = () => {
    //TODO another type of spinner.
    const elem = this.spinnerTarget;
    const instance = M.Modal.init(elem, {
      dismissible: false
    });

    instance.open();
  }

  formSubmit = () => {
    this.formTarget.submit();
  }

  openSpinnerAndSubmit = _event => {
    _event.stopPropagation();
    _event.preventDefault();

    try {
      if (this.checkFormValidity() === true) {
        this.submitButtonTarget.disabled = true;
        this.openSpinner();
        this.formSubmit();
      }
    } catch (err) {
      this.submitButtonTarget.disabled = false;
      throw err;
    }
  }
}
