// these should be simplified and not put on global scope like so
export const check_max_length = element => {
  if(element.value.length === 6) return false;
}
export function toggle_edit_case(target) {
    const container = target.closest('.edit-case')
    container.classList.toggle('show');
    return false;
}
// Remove created Summary PDF modal and it's initialization
export function resetSummaryPdfModal() {
  document.querySelector("#summary-pdf-modal").remove();
}
// Remove created Request Fetch Report modal and it's initialization
export function resetNewRequestCstModal() {
  document.querySelector("#new-request-cst-modal").remove();
}
// Remove created Close Case Request modal and it's initialization
export function resetNewCloseRequestModal() {
  document.querySelector("#new-close-request-modal").remove();
}
// Remove created Order Rx History modal and it's initialization
export function resetOrderRxHistoryModal() {
  document.querySelector("#order-rx-history-modal").remove();
}
// Remove created Close Case Request modal and it's initialization
export function resetNewEnrollInTherapyRequestModal() {
  document.querySelector("#new-enroll-in-therapy-request-modal").remove();
}
// Remove created Close Case Request modal and it's initialization
export function resetEditInvoicingContactEmailRequestModal() {
  document.querySelector("#edit-invoicing-contact-email-request-modal").remove();
}
export function showSubmitProgress() {
  document
    .querySelectorAll(".submit-cst-btn")
    .forEach((each) => each.classList.add("hide"));
  document
    .querySelectorAll(".submit-cst-progress")
    .forEach((each) => each.classList.remove("hide"));
}
// This expands the cases if there are a total of 3 or less, upon initial display
//const caseContainer = document.querySelectorAll("li.case-container");
//if (caseContainer.length <= 3) {
//  caseContainer.forEach((each) => each.classList.add("active"));
//}
