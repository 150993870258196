// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus"
import {
  show_DOM_element,
  hide_DOM_element,
  check_flash_messages,
  initialize_textarea,
  add_new_item,
  toggle_other,
  hide_not_item_added_message,
  toggle_date_stopped_action,
  header_text_input_focus,
  show_tab_medication_required,
  hide_tab_medication_required
} from "./outcomes-lib";
import { show_medication_tab_required } from './outcomes-medication-controller';

export default class extends Controller {
  static targets = [
    'medicationRequiredMessage'
  ];

  initialize() {}

  connect() {
    console.log('Hello, Stimulus from outcomes > AE > controller!');
    check_flash_messages();
    initialize_textarea();
    this.check_adverse_event_loaded();
  };

  check_adverse_event_loaded() {
    const primaryValuesSaved = document.getElementsByClassName('primary-category-value-hidden');
    const primaryValuesSavedList = Array.from(primaryValuesSaved);

    primaryValuesSavedList.forEach(primaryValue => {
      if(!primaryValue.innerText) return;

      const primaryCategoryContainer = primaryValue.closest('.collapsible-header');

      const secondaryCategoryContainer = primaryCategoryContainer.nextSibling;
      const secondaryCategoryValueSaved = secondaryCategoryContainer.getElementsByClassName('secondary-category-value-hidden')[0];
      const secondaryCategorySelect = secondaryCategoryContainer.getElementsByTagName('select')[1];

      const gradeContainerBySecondaryContainer = secondaryCategoryContainer.getElementsByClassName('grade-container')[0];
      const grades = gradeContainerBySecondaryContainer.getElementsByClassName('grade-description');
      const gradesList = Array.from(grades);

      const primaryCategories = secondaryCategorySelect.closest('.select-category').previousSibling.getElementsByTagName('select')[0];

      const primaryCategoryValueText = primaryValue.innerText;
      const secondaryCategoryValueText = secondaryCategoryValueSaved.innerText;

      if(!primaryCategoryValueText) return;

      this.set_category_selected(primaryCategories, primaryCategoryValueText);
      this.load_secondary_categories(secondaryCategorySelect, primaryCategoryValueText, true, secondaryCategoryValueText);
      this.load_grades_by_secondary_category(gradesList, primaryCategoryValueText, secondaryCategoryValueText);
    });
  };

  add_adverse_event = () => {
    const functionsToLoad = [this.reload_data_by_new_adverse_event_record];
    hide_not_item_added_message('adverse-event-new-section');

    const template = {
      identifier: 'new_record',
      name: 'adverseEventTemplate'
    };
    add_new_item('adverse-event-new-section', template, functionsToLoad);
  };

  reload_data_by_new_adverse_event_record = newRecordId => {
    const newAdverseEventRecord = document.getElementById(newRecordId);

    const selects = newAdverseEventRecord.getElementsByTagName('select');
    const primaryCategorySelectElement = selects[0];
    const secondaryCategorySelectElement = selects[1];

    M.FormSelect.init(primaryCategorySelectElement);
    M.FormSelect.init(secondaryCategorySelectElement);
  };

  reset_adverse_event_grades = (grades, checkboxes) => {
    try {
      grades.forEach(grade => grade.innerText = "");
      checkboxes.forEach(checkbox => checkbox.checked = false);
    }
    catch (err) {
      console.log(err);
    }
  };

  event_primary_category_change = _event => {
    try {
      const primaryCategoryElement = _event.target;
      const primaryCategorySelected = primaryCategoryElement.options[primaryCategoryElement.selectedIndex].text;
      if (!primaryCategorySelected) return;

      const bodyContainer = primaryCategoryElement.closest('.collapsible-body');
      const headerContainer = bodyContainer.previousSibling;
      const adverseEventTitle = headerContainer.getElementsByClassName('new-adverse-event-title')[0];
      adverseEventTitle.innerHTML = primaryCategorySelected;

      const primaryCategoryContainer = primaryCategoryElement.closest('.select-category');
      const secondaryCategoryContainer = primaryCategoryContainer.nextSibling;
      const secondaryCategory = secondaryCategoryContainer.getElementsByTagName('select')[0];

      if(secondaryCategory) {
        secondaryCategory.innerText = null;
        M.FormSelect.init(secondaryCategory);
      }

      const gradeContainerBySecondaryContainer = bodyContainer.getElementsByClassName('grade-container')[0];
      const grades = gradeContainerBySecondaryContainer.getElementsByClassName('grade-description');
      const checkboxesInGrades = gradeContainerBySecondaryContainer.getElementsByTagName('input');

      const gradesList = Array.from(grades);
      const checkboxesInGradesList = Array.from(checkboxesInGrades);

      this.reset_adverse_event_grades(gradesList, checkboxesInGradesList);
      this.load_secondary_categories(secondaryCategory, primaryCategorySelected);
    } catch (err) {
      console.log(err);
    }
  };

  event_secondary_category_change = _event => {
    try {
      const secondaryCategoryElement = _event.target;
      const secondaryCategoryContainer = secondaryCategoryElement.closest('.secondary-category');
      const gradeContainerBySecondaryContainer = secondaryCategoryContainer.nextSibling.getElementsByClassName('grade-container')[0];
      const grades = gradeContainerBySecondaryContainer.getElementsByClassName('grade-description');

      const primaryCategory  = secondaryCategoryContainer.previousSibling.getElementsByTagName('select')[0];
      if(!primaryCategory) return;

      const primaryCategorySelected = primaryCategory && primaryCategory.selectedOptions && primaryCategory.selectedOptions[0].innerText;
      if (!primaryCategorySelected) return;

      const secondaryCategorySelected = secondaryCategoryElement.options[secondaryCategoryElement.selectedIndex].text;
      if (!secondaryCategorySelected) return;

      this.load_grades_by_secondary_category(grades, primaryCategorySelected, secondaryCategorySelected);
    } catch (err) {
      console.log(err);
    }
  };

  show_medication_required_by_adverse_event = _event => {
    const element = _event.target;
    const medicationRequiredMessage = element.closest('.adverse-event-concomitant-question').nextSibling;

    show_DOM_element(medicationRequiredMessage);
    show_tab_medication_required();
  };

  hide_medication_required_by_adverse_event = _event => {
    const element = _event.target;
    const medicationRequiredMessage = element.closest('.adverse-event-concomitant-question').nextSibling;
    hide_DOM_element(medicationRequiredMessage);

    if(this.is_medication_required_previous_by_data_saved()) return;

    // check if the mark is not required by another question or section relative to it on the form.
    // In this case We need to get the options regarding the Action section to check it.
    const currentContainer = _event.target.closest('.concomitant-medications-question-container');
    const optionsInActionContainer = currentContainer.closest('.row').previousSibling.previousSibling;
    const medicationRequiredForActionSelected = this.is_required_medication_by_action_selected(optionsInActionContainer);

    if(!medicationRequiredForActionSelected) {
      hide_tab_medication_required();
    }
  };

  select_grade = _event => {
    _event.stopPropagation();
    // event, element, checkboxValueSelected
    const element = _event.target.closest('label');
    const checkboxValueSelected = _event.target.dataset.checkboxValueSelected;

    const gradeContainer =  element.closest('.grade-container');
    const rounds = gradeContainer.getElementsByClassName('round');
    const roundsList = Array.from(rounds);
    const checkboxes = [];

    roundsList.forEach(round => {
      const checkbox = round.getElementsByTagName('input')[0];
      checkboxes.push(checkbox);
    });

    checkboxes.forEach(element => {
      element.checked = element.id === checkboxValueSelected;
    });
  };

  is_medication_required_previous_by_data_saved = () => {
    const isMedicationRequiredByDataSaved = document.getElementById('medicationRequiredByDataSaved');

    // if Medication tab mark is required by data saved as for example in Physical Exam Form
    // We don't need check status for it.
    return (isMedicationRequiredByDataSaved && isMedicationRequiredByDataSaved.value === 'true');
  };

  is_required_medication_by_action_selected = optionsContainer => {
    let isMedicationTabRequired = false;
    const checkboxes = optionsContainer.querySelectorAll('input[type="checkbox"]');

    checkboxes.forEach(checkbox => {
      const checkboxValue = checkbox.value.toLowerCase();

      if((checkboxValue === 'targeted therapy changed' || checkboxValue === 'concomitant medication added') &&
        checkbox.checked) {
        isMedicationTabRequired = true;
      }
    });

    return isMedicationTabRequired;
  };

  set_category_selected = (categoriesList, categorySelected) => {
    const list = Array.from(categoriesList.options);

    const countForList = list.length;
    let indexForSelected;

    for (let i = 0; i < countForList; i++) {
      if(list[i].innerText === categorySelected) {
        indexForSelected = i;
        break;
      }
    }
    categoriesList.options[indexForSelected].selected = 'selected';
    M.FormSelect.init(categoriesList);
  };

  create_second_category_option = (value, text) => {
    const option = document.createElement('option');
    option.value = value;
    option.innerHTML = text;

    return option;
  };

  load_secondary_categories = (secondaryCategory, primaryCategorySelected, markSelected, secondaryCategorySelected) => {
    const req = new XMLHttpRequest();
      const URL = `/adverse_events/vcogs/${primaryCategorySelected}/`;
      const self = this;

      secondaryCategory.innerText = null;

      req.open("GET", URL, true);
      req.send();
      req.onload=function() {
          const data = JSON.parse(req.responseText);
          if(!data || data.length <= 0) return;

          const firstOption = self.create_second_category_option('', 'Select secondary Category');
          secondaryCategory.append(firstOption);

          data.forEach(item => {
            const option = self.create_second_category_option(item, item);
            secondaryCategory.append(option);
          });

          M.FormSelect.init(secondaryCategory);

          if(markSelected) {
            self.set_category_selected(secondaryCategory, secondaryCategorySelected);
          }
      }
  };

  load_grades_by_secondary_category = (grades, primaryCategorySelected, secondaryCategorySelected) => {
    const req = new XMLHttpRequest();
      const URL = `/adverse_events/vcogs/${primaryCategorySelected}/secondary/${secondaryCategorySelected}`;

      req.open("GET", URL, true);
      req.send();
      req.onload=function() {
          if(!req.responseText) return;

          const parsedData = JSON.parse(req.responseText);
          if(!parsedData) return;

          const grade1 = grades[0];
          grade1.innerText = parsedData.grade_1;

          const grade2 = grades[1];
          grade2.innerText = parsedData.grade_2;

          const grade3 = grades[2];
          grade3.innerText = parsedData.grade_3;

          const grade4 = grades[3];
          grade4.innerText = parsedData.grade_4;

          const grade5 = grades[4];
          grade5.innerText = parsedData.grade_5;
      };
  };

  toggle_with_medication_message = _event => {
    toggle_other(_event);
    this.check_medication_required_by_adverse_event_action_selected(_event);
  };

  check_medication_required_by_adverse_event_action_selected = _event => {
    if(this.is_medication_required_previous_by_data_saved()) return;

    const optionsContainer = _event.target.closest('label').closest('.options-container');
    let showMedicationTabRequired = this.is_required_medication_by_action_selected(optionsContainer);
    if(showMedicationTabRequired) {
      show_tab_medication_required();
    } else {
      // check if the mark is not required by another question relative to it on the form.
      const medicationRequiredMessageByChangedElement =  this.medicationRequiredMessageTarget;
      const medicationRequiredMessageByChanged = window.getComputedStyle(medicationRequiredMessageByChangedElement);

      if(medicationRequiredMessageByChanged.display === 'none') {
        hide_tab_medication_required();
      }
    }
  };

  toggle_adverse_event_serious = (_event) => {
    const element = _event.target.closest('label');
    const value = element.dataset.isEventSerious;
    const seriousEventContainer = element.closest('.serious-event');
    const noteContainer = seriousEventContainer.previousSibling;

    return value === 'Yes' ? show_DOM_element(noteContainer) : hide_DOM_element(noteContainer);
  };

  toggle_other_describe = _event => {
    toggle_other(_event);
  };

  toggle_date_stopped = _event => {
    toggle_date_stopped_action(_event);
  };

  header_text_focus(_event) {
    header_text_input_focus(_event);
  };
}
