import { _refinedDisjunctiveFacets, _isRefinedOrQuerried, _showHideTargetElementOnQuerryOrRefined } from './common.js'
// This Widget is Responsible for Renders inactive facets (this is a bit of a hack but unfortunately)
// but algolia doesnt return facets with value 0 https://discourse.algolia.com/t/default-search-refinement-list/1835/4

export function statsWidget (resetSearchElementId) {
  return {
    init: function (params) {
      const helper = params.helper;

      // define reset function
      function resetHandler() {
        helper.setQuery('').clearRefinements().search();
      }

      // bind on click for reset
      document.querySelector(resetSearchElementId).addEventListener('click',  resetHandler);

    },
    render: function (params) {
      const results = params.results;
      const state = params.state;

      // hide or show based on refinement
      _showHideTargetElementOnQuerryOrRefined(state, resetSearchElementId);
    }
  }
}
