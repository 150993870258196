// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
// .container data-controller="hello"
//   .section data-target="hello.output"

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    'supportStaffFirstName',
    'supportStaffLastName',
    'supportStaffEmail',
    'submitterVetFalse',
    'submitterVetTrue',
    'submitterInfo'
  ];

  initialize() {}

  connect() {
  }

  show_hide_submitter = _event => {
    const radio = _event.target;
    if (radio.value === "support_staff") {
      this.show_target_input(this.submitterInfoTarget);
      this.make_target_input_required(this.supportStaffFirstNameTarget);
      this.make_target_input_required(this.supportStaffLastNameTarget);
      this.make_target_input_required(this.supportStaffEmailTarget);
    }
    else {
      this.hide_target_input(this.submitterInfoTarget);
      this.make_target_input_unrequired(this.supportStaffFirstNameTarget);
      this.make_target_input_unrequired(this.supportStaffLastNameTarget);
      this.make_target_input_unrequired(this.supportStaffEmailTarget);
    }
  }

  make_target_input_required = inputTarget => {
    if (inputTarget !== null) {
      inputTarget.required = true;
    }
  }

  make_target_input_unrequired = inputTarget => {
    if (inputTarget !== null) {
      inputTarget.required = false;
    }
  }

  show_target_input = inputTarget => {
    if (inputTarget !== null) {
      inputTarget.classList.remove('hide');
    }
  }

  hide_target_input = inputTarget => {
    if (inputTarget !== null) {
      inputTarget.classList.add('hide');
    }
  }
}
