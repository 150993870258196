// This Widget is Responsible for Renders inactive facets (this is a bit of a hack but unfortunately)
// but algolia doesnt return facets with value 0 https://discourse.algolia.com/t/default-search-refinement-list/1835/4
import { _refinedDisjunctiveFacets, _isRefinedOrQuerried, _showHideTargetElementOnQuerryOrRefined, noResultsTemplate } from './common.js'
export function statsWidget(resetSearchElementId) {
  return {
    init: function (params) {
      const helper = params.helper;

      // define reset function
      function resetHandler() {
        helper.setQuery("").clearRefinements().search();
      }

      // bind on click for reset
      document
        .querySelector(resetSearchElementId)
        .addEventListener("click", resetHandler);
    },
    render: function (params) {
      const results = params.results;
      const state = params.state;

      // hide or show Stats and Reset search based on refinement
      _showHideTargetElementOnQuerryOrRefined(state, resetSearchElementId);

      // Hide/Show results based on refinement
      _showHideTargetElementOnQuerryOrRefined(state, "#cases-hits");

      // Show/Hide backend results based on refinement
      const hitsFromBackend = document.querySelector(
        "#cases-hits-from-backend"
      );
      if (hitsFromBackend===null) { return }
      _isRefinedOrQuerried(state)
        ? hitsFromBackend.classList.add("hide")
        : hitsFromBackend.classList.remove("hide");
    },
  };
}
